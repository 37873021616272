import React from 'react';
import 'swiper/swiper-bundle.css';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing/Landing';
import Login from './pages/Login/Login';
import SignUp from './pages/SignUp/SignUp';
import Dashboard from './pages/Dashboard/Dashboard';
import Assessments from './pages/Assessments/Assessments';
import Courses from './pages/Courses/Courses';
import CourseDetails from './pages/CourseDetails/CourseDetails';
import StartLearning from './pages/StartLearning/StartLearning';
import Jobs from './pages/Jobs/Jobs';
import ApplyJob from './pages/ApplyJob/ApplyJob';
import AssessmentsOverview from './pages/AssessmentsOverview/AssessmentsOverview';
import AssessmentsForm from './pages/AssessmentsForm/AssessmentsForm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlogDetail from './components/Landing/BlogDetail';
import Certificate from './components/Landing/Certificate';
import JobResults from './components/Landing/JobResults';
import AssessmentResult from './pages/AssessmentResult/AssessmentResult';
import BookADemo from './pages/BookADemo/BookADemo';
import MainContent from './components/Dashboard-new/MainContent';
import AssessmentReport from './pages/AssessmentReport/AssessmentReport';
import Landing02 from './pages/Landing-02/landing-02';
import CommingSoon from './pages/Landing-02/comming-soon/comming-soon';
import Home from './pages/Home/Home';

function App() {
  return (  
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {/* <Route path="/" element={<Landing02 />} /> */}
        <Route path="/" element={<Landing02 />} />
        <Route path="/new-home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        {/* <Route path="/dashboard" element={<AssessmentReport />} /> */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/MainContent" element={<MainContent />} />

        <Route path="/assessments" element={<Assessments />} />
        <Route path="/results" element={<JobResults />} />
        <Route
          path="/assessment-overview/:id"
          element={<AssessmentsOverview />}
        />
        <Route path="/assessments/result" element={<AssessmentResult />} />
        <Route path="/assessment-form" element={<AssessmentsForm />} />
        <Route path="/blogs/:blogId" element={<BlogDetail />} />
        <Route path="/certificate" element={<Certificate />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/course-details" element={<CourseDetails />} />
        <Route path="/start-learning" element={<StartLearning />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/apply-job" element={<ApplyJob />} />
        <Route path="/book-a-demo" element={<BookADemo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
